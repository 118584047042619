.divider {
  position: relative;
  width: var(--lineWidth);
  height: var(--lineHeight);
}

.divider--thin {
  height: 1px;
}

.divider__line {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgb(var(--rgbPrimary));
  opacity: 1;
  transition-property: opacity;
  transition-duration: var(--durationL);
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-delay: var(--collapseDelay);
  transform-origin: left center;
  transform: scaleX(1);

  @media (--mediaUseMotion) {
    & {
      transition-property: transform, opacity;
    }
  }
}

.divider__line--collapsed {
  opacity: 0;
  transform: scaleX(0);
}
