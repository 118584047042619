.footer {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: center;
  width: 100vw;
  padding: var(--space5XL) var(--spaceL);
  z-index: 16;
  position: relative;
  font-size: var(--fontSizeBody2XS);
  color: var(--colorTextLight);

  @media (--mediaLaptop) {
    & {
      padding: var(--space3XL) var(--spaceL);
    }
  }

  @media (--mediaTablet) {
    & {
      padding: var(--space2XL) var(--spaceL);
    }
  }

  @media (--mediaMobile) {
    & {
      padding: var(--space3XL) var(--spaceL);
    }
  }


}

.footer__link {
  display: inline-flex;
  font-size: var(--fontSizeBody2XS);
  margin-left: 2px;
  margin-right: 2px;  
}

.footer__link--last {
  font-size: var(--fontSizeBody3XS);
  margin-right: 5px;  
}

.footer__date {
  padding-right: var(--spaceXS);
  display: inline-flex;
}

.footer__other-sites {
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: var(--fontSizeBodyXS);
  padding-bottom: 5px;
}

.footer__other-sites .footer__link {
  font-size: var(--fontSizeBodyXS);
}

.footer__other-sites:nth-child(2) {
  margin-bottom: 10px;
}