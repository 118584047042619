.heading {
  display: block;
  line-height: var(--lineHeightTitle);
  color: var(--colorTextTitle);
}

.heading--level-0 {
  letter-spacing: -0.006em;
  font-size: var(--fontSizeH0);
}

.heading--level-1 {
  letter-spacing: -4px;
  font-size: var(--fontSizeH2);
  @media (--mediaMobile), (max-height: 696px) {
    & {
      letter-spacing: -1px;
    }
  }
}

.heading--level-2 {
  font-size: var(--fontSizeH3);
  letter-spacing: -2px;
  @media (--mediaMobile), (max-height: 696px) {
    & {
      letter-spacing: -1px;
    }
  }
}

.heading--level-3 {
  font-size: var(--fontSizeH3);
}

.heading--level-4 {
  font-size: var(--fontSizeH4);
}

.heading--level-5 {
  font-size: var(--fontSizeH5);
}

.heading--align-auto {
  text-align: inherit;
}

.heading--align-start {
  text-align: start;
}

.heading--align-center {
  text-align: center;
}

.heading--weight-regular {
  font-weight: var(--fontWeightRegular);
}

.heading--weight-medium {
  font-weight: var(--fontWeightMedium);
}

.heading--weight-bold {
  font-weight: var(--fontWeightBold);
}
